import { cast, detach, flow, Instance, types } from 'mobx-state-tree'

import { getChannels, IGetChannelsResponse } from '../../api/assets-api/contentId/getChannels'

import { CHANNEL_UUID_REGEX, SPLIT_STATUSES } from '../../constants'
import { Pagination } from '../general/Pagination.model'

import { Channel } from './Channel.model'

export const ChannelList = types
  .model({
    list: types.array(Channel),
  })
  .volatile(() => ({
    loading: true,
    pagination: Pagination.create({ totalItems: 1 }),
    userUuidFilter: '',
    siteUuidFilter: '',
    nameFilter: '',
    statusFilter: '',
    splitStatusFilter: '',
    unassignedFilter: false,
    onSongpitchFilter: null as null | boolean,
    genresFilter: [] as string[],
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(channel => channel.uuid === uuid)
    },
    setLoading(val: boolean) {
      self.loading = val
    },
  }))
  .actions(self => ({
    load: flow(function* (shouldLoadAll?: boolean, loadFragment?: string) {
      try {
        self.loading = true

        const param = CHANNEL_UUID_REGEX.test(self.nameFilter) ? 'uuid' : 'name'

        const variables = {
          ...(shouldLoadAll ? { pagination: { perPage: -1 } } : { pagination: self.pagination.allQueryParams }),
          filters: {
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.nameFilter && { [param]: self.nameFilter }),
            ...(self.unassignedFilter && { userUuid: '' }),
            ...(self.onSongpitchFilter !== null && { onSongpitch: self.onSongpitchFilter }),
          },
        }
        const resp: IGetChannelsResponse = yield getChannels(variables, loadFragment)
        if (resp && resp.data.data?.channels) {
          detach(self.list)
          self.list = cast(resp.data.data.channels.channels)
          const total = shouldLoadAll ? 1 : resp.data.data.channels.total
          self.pagination.setTotalItems(total)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    setUserUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid.toLowerCase()
    },
    setSiteUuidFilter(site: string) {
      self.siteUuidFilter = site
    },
    setStatusFilter(status: string) {
      self.statusFilter = status
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setNameFilter(text: string) {
      self.nameFilter = text.toLowerCase()
    },
    setGenresFilter(genresUuids: string[]) {
      self.genresFilter = genresUuids
    },
    setOnSongpitchFilter(val: boolean) {
      self.onSongpitchFilter = val
    },
    setUnassignedFilter(newValue: boolean) {
      self.unassignedFilter = newValue
    },
  }))

export type IChannelList = Instance<typeof ChannelList>
