import { SERVICE_NAME } from '../../../constants'
import { IUserGroup } from '../../../models/users/UserGroup.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ICreateUserGroupResponse = IGenericResponse<'createUserGroup', IUserGroup>

export type ICreateUserGroupProps = {
  name: string
}

export async function createUserGroup({ name }: ICreateUserGroupProps): Promise<ICreateUserGroupResponse> {
  const query = `
    mutation ($name: String!) {
      createUserGroup (name: $name) {
        uuid
        name
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { name }) as Promise<ICreateUserGroupResponse>
}
