import { Instance, flow, types } from 'mobx-state-tree'
import {
  IUpdateContactFormStatusResponse,
  updateContactFormStatus,
} from '../../api/users-api/contactRequests/updateContactFormStatus'
import { JSONPrimitive } from '../../constants'

export const ContactRequest = types
  .model({
    uuid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    content: JSONPrimitive,
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
    subjectType: types.maybe(types.string),
    siteUuid: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    updateStatus: flow(function* (status: string) {
      try {
        if (self.uuid && status) {
          self.loading = true
          const resp: IUpdateContactFormStatusResponse = yield updateContactFormStatus({
            uuid: self.uuid,
            status,
          })
          if (resp?.data.data?.updateContactFormStatus.uuid) {
            self.status = status
          }
          self.loading = false
        }
      } catch (e) {
        self.loading = false
        console.error(e)
      }
    }),
  }))

export type IContactRequest = Instance<typeof ContactRequest>
