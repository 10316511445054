import { MICROLICENSING_AUDIO_TYPE, MICROLICENSING_KEY, MICROLICENSING_MOODS, SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import { IMicrolicensingPropsConfigFile } from '../../../models/assets/MicrolicensingPropsConfigFile.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IEditMetadataProps {
  assetUuid: string
  metadata: {
    moods?: MICROLICENSING_MOODS[] | null
    genres?: string[] | null
    audioType?: MICROLICENSING_AUDIO_TYPE | null
    bpm?: number | null
    key?: MICROLICENSING_KEY | null
    shortDescription?: string | null
    description?: string | null
    files: Pick<IMicrolicensingPropsConfigFile, 'filePath' | 'extension'>[] | null
  }
}

export type IEditMetadataResponse = IGenericResponse<'editMetadata', IAssetBasic>

export async function editMetadata({ assetUuid, metadata }: IEditMetadataProps): Promise<IEditMetadataResponse> {
  const query = `
    mutation EditMetadata($metadata: MicrolicensingPropsInput!, $assetUuid: String!) {
      editMetadata(metadata: $metadata, assetUuid: $assetUuid) {
        uuid
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { assetUuid, metadata }) as Promise<IEditMetadataResponse>
}
