import { cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { getGenres } from '../../api'
import { IGetGenresResponse, IGetGenresResponseBody } from '../../api/types'
import { DELIVERY_PLATFORM } from '../../constants/assets/assetDefinition'
import { Pagination } from '../general/Pagination.model'
import { Genre } from './Genre.model'
import { IGenreItem } from './GenreItem.model'

export const GenreList = types
  .model({
    list: types.array(Genre),
  })
  .named('Genres')
  .volatile(() => ({
    loading: true,
    pagination: Pagination.create({ totalItems: 1 }),
    nameFilter: '',
    platform: DELIVERY_PLATFORM.AUDIO_SALAD,
  }))
  .views(self => ({
    get filtered() {
      return self.list.filter(item => {
        return item.name.toLowerCase().includes(self.nameFilter)
      })
    },
  }))
  .views(self => ({
    get pagedFiltered() {
      return self.filtered.slice(
        (self.pagination.page - 1) * self.pagination.perPage,
        self.pagination.page * self.pagination.perPage
      )
    },
  }))
  .actions(self => ({
    setGenres: (genres: IGetGenresResponseBody[]) => {
      self.list = cast(genres)
    },
    setNameFilter(searchTerm: string) {
      self.nameFilter = searchTerm
    },
    setPlatform(platform: DELIVERY_PLATFORM) {
      self.platform = platform
    },
  }))
  .actions(self => ({
    load: flow(function* (successMessage = '') {
      try {
        self.loading = true
        // NOTE update platform for genre if needed
        const resp: IGetGenresResponse = yield getGenres({ platformGenre: self.platform })
        if (resp && resp.data.data?.genres.length) {
          self.setGenres(resp.data.data.genres)
          if (successMessage) {
            toast.success(successMessage)
          }
          self.pagination.setTotalItems(self.list.length)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))
  .views(self => ({
    get flatten() {
      const collection: IGenreItem[] = []
      self.list.forEach(genre => {
        collection.push({ uuid: genre.uuid, name: genre.name })
        genre.subGenres.forEach(subGenre => {
          const { uuid, name } = subGenre
          collection.push({ uuid, name })
        })
      })
      return collection
    },
  }))
  .actions(self => ({
    getGenreById: (id: string): IGenreItem | null => {
      const foundItem = self.flatten.find(item => item.uuid === id)
      if (foundItem) {
        return foundItem
      }
      return null
    },
  }))

export type IGenreList = Instance<typeof GenreList>
