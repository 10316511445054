import { Instance, types } from 'mobx-state-tree'

import { APPROVAL_STATES, ASSET_TYPE, RELEASE_FORMATS } from '../../constants'
import { AssetUser } from './AssetUser.model'

import { Image } from '../general/Image.model'
import { DeliveryPlatform } from './DeliveryPlatformModel'
import { MicrolicensingPropsConfig } from './MicrolicensingPropsConfig.model'
import { ParticipantWithRole } from './ParticipantWithRole.model'
import { Store } from './StoreModel'

export const AssetBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  upc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  assetTypes: types.maybeNull(types.array(types.enumeration(Object.values(ASSET_TYPE)))),
  userUuid: types.maybeNull(types.string),
  users: types.array(AssetUser),
  videoId: types.maybeNull(types.string),
  isrc: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  displayArtist: types.maybeNull(types.string),
  streamingLinks: types.maybeNull(
    types.array(
      types.model({
        link: types.string,
        store: types.string,
      })
    )
  ),
  participants: types.maybeNull(types.array(ParticipantWithRole)),
  genreUuids: types.maybeNull(types.array(types.string)),
  cInfo: types.maybeNull(types.string),
  cYear: types.maybeNull(types.number),
  pInfo: types.maybeNull(types.string),
  pYear: types.maybeNull(types.number),
  rightsHolders: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  flag: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
  customId: types.maybeNull(types.string),
  csAssetId: types.maybeNull(types.string),
  csCustomId: types.maybeNull(types.string),
  activeReferenceId: types.maybeNull(types.string),
  inactiveReferenceId: types.maybeNull(types.string),
  csSyncedAt: types.maybeNull(types.string),
  isClaimSynced: types.maybeNull(types.boolean),
  claimSyncedAt: types.maybeNull(types.string),
  earningSyncedAt: types.maybeNull(types.string),
  publishDate: types.maybeNull(types.string),
  publishingSyncedAt: types.maybeNull(types.string),
  importedPhpAt: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  aliasesIds: types.maybeNull(types.array(types.string)),
  sites: types.array(types.string),
  isActive: types.maybeNull(types.boolean),
  channel: types.maybeNull(
    types.model({
      name: types.string,
      uuid: types.string,
    })
  ),
  channelUuid: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  storagePath: types.maybeNull(types.string),
  uploadFinished: types.maybeNull(types.boolean),
  assetLabels: types.maybeNull(types.string),
  matchPolicy: types.maybeNull(types.string),
  importSource: types.maybeNull(types.string),
  assetState: types.maybeNull(types.enumeration(Object.values(APPROVAL_STATES))),
  clientStatus: types.maybeNull(types.string),
  approvalComment: types.maybeNull(types.string),
  approvedAt: types.maybeNull(types.string),
  rejectedAt: types.maybeNull(types.string),
  approvalEmailSentAt: types.maybeNull(types.string),
  titleVersion: types.maybeNull(types.string),
  releaseFormat: types.maybeNull(types.enumeration(Object.values(RELEASE_FORMATS))),
  recordingLocation: types.maybeNull(types.string),
  originalReleaseDate: types.maybeNull(types.string),
  preorderDate: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  series: types.maybeNull(types.string),
  compilation: types.maybeNull(types.boolean),
  metadataLanguage: types.maybeNull(types.string),
  displayImagePath: types.maybeNull(types.string),
  generalInfoStepPassed: types.maybeNull(types.boolean),
  trackInfoStepPassed: types.maybeNull(types.boolean),
  targetPlatformStepPassed: types.maybeNull(types.boolean),
  bpm: types.maybeNull(types.number),
  iswc: types.maybeNull(types.string),
  audioLanguage: types.maybeNull(types.string),
  audios: types.maybeNull(types.array(types.model({ uuid: types.string }))),
  grid: types.maybeNull(types.string),
  songTitle: types.maybeNull(types.string),
  artistIsni: types.maybeNull(types.string),
  album: types.maybeNull(types.string),
  genre: types.maybeNull(types.string),
  isCover: types.maybeNull(types.boolean),
  releaseUuids: types.maybeNull(types.array(types.string)),
  srExist: types.maybeNull(types.boolean),
  images: types.optional(types.array(Image), []),
  trackOrder: types.optional(types.array(types.string), []),
  description: types.maybeNull(types.string),
  referenceExclusions: types.maybeNull(types.string),
  claimId: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  owned: types.maybeNull(types.string),
  downloadUrl: types.maybeNull(types.string),
  totalViews: types.maybeNull(types.number),
  totalClaims: types.maybeNull(types.number),
  fileSizeInBytes: types.maybeNull(types.number),
  isCompositionShare: types.maybeNull(types.boolean),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  hasCustomId: types.maybeNull(types.boolean),
  advisory: types.maybeNull(types.string),
  previewStart: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  validationErrors: types.maybeNull(
    types.array(
      types.model({
        field: types.string,
        message: types.string,
      })
    )
  ),
  ddexTakedown: types.maybeNull(types.boolean),
  platforms: types.maybeNull(types.array(DeliveryPlatform)),
  srCmsDestination: types.maybeNull(types.string),
  stores: types.maybeNull(types.array(Store)),
  asJson: types.maybeNull(types.string),
  forceAssignIsrc: types.maybeNull(types.boolean),
  microlicensingProps: types.maybeNull(MicrolicensingPropsConfig),
})

export type IAssetBasic = Instance<typeof AssetBasic>
