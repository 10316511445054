import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORY, GA_ID, SortDirection } from '@epic-front/common/src/constants'
import { IPagination, Pagination as PaginationModel } from '@epic-front/common/src/models/general/Pagination.model'
import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ElementType, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import ReactGA from 'react-ga4'
import Pagination from '../Pagination'
import { ITableColumn } from './Table.types'
import TableRow from './TableRow'

export const ROW_CLASSES = {
  SUCCESS: 'table-success',
  ERROR: 'table-danger',
  WARNING: 'table-warning',
  INFO: 'table-info',
  LIGHT: 'table-light',
  SECONDARY: 'table-secondary',
}

export const NO_DATA_MESSAGE = 'No data for this listing'

export interface ITable<T> {
  columns: ITableColumn<T>[]
  data: T[]

  rowIdentifier: (row: T) => string // unique identifier for the row, usually: (row) =>  row.uuid

  tableClasses?: string // extra classes we want to add to the table
  rowClasses?: (row: T) => string // classes added to each row based on row props
  tableHeadClasses?: string // extra classes we want to add to the table header
  isExpandable?: boolean
  isPaginated?: boolean
  showRowNumber?: boolean // show the row nr on first column
  rowNumberTop?: boolean

  isSelectable?: boolean
  selectedRows?: string[]
  onSelectRow?: (selectedList: string[]) => void
  selectableRowDisabled?: (row: T) => boolean // function that checks if the row checkbox should be disabled

  isDraggable?: boolean
  handleOnDrop?: (rowUuid: string, newPosition: number) => void

  expandableRowDisabled?: (row: T) => boolean // function that checks if the row is expandable
  ExpandableRowComponent?: ElementType<{ row: T }>

  hasAdvancedView?: boolean // to show the advance view switch and show/hide hidden columns

  initialPage?: number
  onPageChange?: (pagination: IPagination) => void // we can use this to load pages from server
  totalItemsOverride?: number

  isLoading?: boolean
  hasFinalRow?: boolean // if we show an extra row the the bottom ex: with total values
  displayFinalRowNoData?: boolean // display the final row even when the table has no data

  externalPagination?: IPagination
  stickyHeader?: boolean

  tableWrapClass?: string // extra class for the table wrap
  paginationWrapClass?: string // extra class for the pagination wrap

  noDataMessage?: string
}

/**
 * Renders a table
 * for better typing we should pass the type when using the table
 */
function Table<T>({
  data,
  columns,
  tableClasses,
  tableHeadClasses,
  rowClasses,
  isExpandable,
  ExpandableRowComponent,
  expandableRowDisabled,
  isPaginated,
  isSelectable,
  isDraggable,
  handleOnDrop,
  onSelectRow,
  rowIdentifier,
  hasAdvancedView,
  showRowNumber,
  rowNumberTop,
  initialPage,
  onPageChange,
  isLoading,
  totalItemsOverride,
  externalPagination,
  selectedRows,
  hasFinalRow,
  displayFinalRowNoData,
  selectableRowDisabled,
  stickyHeader,
  tableWrapClass,
  paginationWrapClass,
  noDataMessage = NO_DATA_MESSAGE,
}: ITable<T>): JSX.Element {
  const [advancedSwitchId] = useState<string>(uniqueId('advance-switch-'))

  const totalItems = externalPagination?.totalItems || totalItemsOverride || data.length

  const [internalPagination] = useState<IPagination>(PaginationModel.create({ totalItems, page: initialPage }))

  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [shownData, setShownData] = useState<T[]>([])

  const refTable = useRef<HTMLTableElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)

  const totalColumns = columns.length + (isExpandable ? 1 : 0) + (showRowNumber ? 1 : 0) + (isSelectable ? 1 : 0)

  const [isShowingAdvanced, setIsShowingAdvanced] = useState<boolean>(false)

  const pagination = externalPagination || internalPagination

  // it's important to return autorun so that it gets the updated mst object
  // this is used when we have internal pagination only
  useEffect(
    () =>
      autorun(() => {
        if (!externalPagination) {
          pagination.setTotalItems(totalItems)
        }
      }),
    [totalItems, pagination]
  )

  // when the total nr of pages changes check to see if we are on existing page and reset the page
  useEffect(() => {
    if (internalPagination.totalPages < internalPagination.page) {
      internalPagination.setPage(1)
    }
  }, [internalPagination.totalPages, internalPagination.page])

  // add more dependencies here as we add sort and sort direction
  useEffect(
    () =>
      autorun(() => {
        if (onSelectRow && typeof selectedRows !== 'undefined') {
          onSelectRow([])
        }
        if (onPageChange) {
          onPageChange(pagination)
        }
      }),
    [pagination.perPage, pagination.page, pagination.sort, pagination.sortDirection, onPageChange]
  )

  useEffect(
    () =>
      autorun(() => {
        let newShownData
        if (externalPagination) {
          newShownData = data
        } else {
          newShownData = isPaginated
            ? data.slice((pagination.page - 1) * pagination.perPage, pagination.perPage * pagination.page)
            : data
        }

        setShownData(newShownData)
      }),
    [isPaginated, data, pagination.page, pagination.perPage, pagination.sort, pagination.sortDirection]
  )

  useEffect(() => {
    if (isLoading) {
      if (isExpandable) {
        setExpandedRows([])
      }
    }
  }, [isLoading])

  const handleToggleExpandRow = useCallback(
    (rowIndex: number) => {
      if (GA_ID) {
        ReactGA.event({
          category: GA_EVENT_CATEGORY.TABLE_INTERACTION,
          action: GA_EVENT_ACTIONS.EXPAND,
        })
      }

      // check if row is already expanded
      const exitingIndex = expandedRows.indexOf(rowIndex)
      if (exitingIndex > -1) {
        // remove the element
        setExpandedRows(oldArray => {
          oldArray.splice(exitingIndex, 1)
          return [...oldArray]
        })
        return
      }
      // add the new expanded row index
      setExpandedRows(oldArray => [...oldArray, rowIndex])
    },
    [expandedRows]
  )

  const handleToggleRow = useCallback(
    (rowId: string) => {
      if (!onSelectRow || typeof selectedRows === 'undefined') {
        return
      }
      // see if it is checked and update the list
      const newSelectedList = selectedRows.includes(rowId)
        ? [...selectedRows.filter(item => item !== rowId)]
        : [...selectedRows, rowId]
      onSelectRow(newSelectedList)
    },
    [selectedRows]
  )

  const handleSelectAll = useCallback(() => {
    if (!onSelectRow || typeof selectedRows === 'undefined') {
      return
    }
    // check if all are selected
    if (selectableRowDisabled) {
      if (selectedRows.length === shownData.filter(row => !selectableRowDisabled(row)).length) {
        onSelectRow([])
        return
      }
    } else if (selectedRows.length === shownData.length) {
      // deselect all
      onSelectRow([])
      return
    }
    // select all rows and filter out the disabled ones if any
    const newSelectedRows = shownData
      .filter(row => {
        if (!selectableRowDisabled) {
          return true
        }

        return !selectableRowDisabled(row)
      })
      .map(row => rowIdentifier(row))

    onSelectRow(newSelectedRows)
  }, [selectedRows, onSelectRow, rowIdentifier, shownData])

  const handleToggleAdvanced = useCallback(
    () =>
      autorun(() => {
        if (GA_ID && !isShowingAdvanced) {
          ReactGA.event({
            category: GA_EVENT_CATEGORY.TABLE_INTERACTION,
            action: GA_EVENT_ACTIONS.SHOW_ADVANCE,
          })
        }

        setIsShowingAdvanced(oldValue => !oldValue)
      }),
    []
  )

  const allChecked = useMemo(() => {
    if (!(selectedRows && selectedRows.length > 0)) {
      return false
    }
    if (selectableRowDisabled) {
      return selectedRows.length === shownData.filter(row => !selectableRowDisabled(row)).length
    }

    return selectedRows.length === shownData.length
  }, [selectedRows, selectableRowDisabled, shownData])

  // handles the  column header press action
  // changes the sort direction DESC -> ASC -> unset
  const handleSortChange = useCallback(
    (col: ITableColumn<T>) => {
      if (GA_ID) {
        ReactGA.event({
          category: GA_EVENT_CATEGORY.TABLE_INTERACTION,
          action: GA_EVENT_ACTIONS.SORT,
          label: col.accessor,
        })
      }

      if (pagination.sortDirection && pagination.sort === col.accessor) {
        if (pagination.sortDirection === SortDirection.DESC) {
          pagination.setSortDirection(SortDirection.ASC)
          pagination.setSort(col.accessor || null)
        } else {
          // unset
          pagination.setSortDirection(SortDirection.ASC) // this is the default value
          pagination.setSort(null)
        }
      } else {
        pagination.setSortDirection(SortDirection.DESC)
        pagination.setSort(col.accessor || null)
      }

      pagination.setPage(1)
    },
    [pagination]
  )

  return (
    <div className={`table-wrap ${tableWrapClass}`}>
      {hasAdvancedView && (
        <div className="AdvancedView">
          <div className="form-check form-switch px-3 py-2 d-flex align-items-center gap-2">
            <input
              className="form-check-input form-check-input-lg m-0 border-0"
              type="checkbox"
              id={advancedSwitchId}
              onChange={handleToggleAdvanced}
            />
            <label className="form-check-label user-select-none lh-1 flex-grow-1" htmlFor={advancedSwitchId}>
              <span className="fw-normal font-13">Advanced View</span>
            </label>
          </div>
        </div>
      )}
      <div ref={refWrapper} className={classNames(['table-responsive', { 'sticky-thead': stickyHeader }])}>
        <table ref={refTable} className={classNames('table table-light table-hover border-1', tableClasses)}>
          <thead className={classNames('align-bottom table-dark border border-dark', tableHeadClasses)}>
            <tr>
              {isSelectable && typeof selectedRows !== 'undefined' && (
                <th className="mincol">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={allChecked}
                      onChange={handleSelectAll}
                    />
                  </div>
                </th>
              )}
              {isExpandable && <th className="mincol"> </th>}

              {showRowNumber && <th className="mincol text-center pe-3">#</th>}

              {columns.map(col =>
                col.hidden && !isShowingAdvanced ? null : (
                  <th className={classNames(col.headerCellClasses, col.hidden && 'hidden-col')} key={col.accessor}>
                    {col.sortable ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            handleSortChange(col)
                          }}
                          className="btn border-0 p-0 m-0 d-flex align-items-center"
                        >
                          <span className="d-inline">{col.Header}</span>

                          <svg
                            version="1.1"
                            className={classNames(
                              'table-arrows',
                              {
                                'col-ordered-down':
                                  pagination.sort === col.accessor && pagination.sortDirection === SortDirection.DESC,
                              },
                              {
                                'col-ordered-up':
                                  pagination.sort === col.accessor && pagination.sortDirection === SortDirection.ASC,
                              }
                            )}
                            x="0px"
                            y="0px"
                            viewBox="0 0 24 24"
                          >
                            <g clipPath="url(#clip0_1201_29786)">
                              <path d="M21 8.99998L12 0L3 8.99998H21Z" className="arr-bot" />
                              <path d="M3 15L12 24L21.0001 15H3Z" className="arr-top" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1201_29786">
                                <rect width="24" height="24" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </>
                    ) : (
                      col.Header
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          {isLoading && (
            <thead>
              <tr>
                <th colSpan={totalColumns} className="text-center">
                  <Spinner animation="border" />
                </th>
              </tr>
            </thead>
          )}

          <tbody>
            {shownData.map((row, rowIndex) => {
              const rowId = rowIdentifier(row)
              return (
                <TableRow<T>
                  key={rowId}
                  row={row}
                  rowId={rowId}
                  rowIndex={rowIndex}
                  columns={columns}
                  totalColumns={totalColumns}
                  expandedRows={expandedRows}
                  pagination={pagination}
                  isShowingAdvanced={isShowingAdvanced}
                  showRowNumber={showRowNumber}
                  rowNumberTop={rowNumberTop}
                  isExpandable={isExpandable}
                  isDraggable={isDraggable}
                  handleOnDrop={handleOnDrop}
                  isSelectable={isSelectable}
                  selectedRows={selectedRows}
                  rowClasses={rowClasses}
                  selectableRowDisabled={selectableRowDisabled}
                  expandableRowDisabled={expandableRowDisabled}
                  ExpandableRowComponent={ExpandableRowComponent}
                  handleToggleRow={handleToggleRow}
                  handleToggleExpandRow={handleToggleExpandRow}
                />
              )
            })}
            {hasFinalRow && (shownData.length !== 0 || displayFinalRowNoData) && (
              <tr>
                {isSelectable && typeof selectedRows !== 'undefined' && <td className="mincol">{/* Empty row */}</td>}
                {/* Adding an extra column if the row is expandable with an expand button */}
                {isExpandable && <td className="mincol">{/* Empty row */}</td>}
                {/* Show the row number based on all pages  */}
                {showRowNumber && <td> {/* Empty row */}</td>}

                {/* Listing the columns content */}
                {columns.map(col =>
                  col.hidden && !isShowingAdvanced ? null : (
                    <td className={classNames(col.cellClasses)} key={col.accessor || col.Header.toString()}>
                      {col.finalRow && col.finalRow(shownData)}
                    </td>
                  )
                )}
              </tr>
            )}
          </tbody>
        </table>

        {!isLoading && shownData.length === 0 && <div className="alert alert-info m-3">{noDataMessage}</div>}
      </div>

      <div className={`pagination-wrap ${paginationWrapClass}`}>
        {isPaginated && <Pagination pagination={pagination} />}
      </div>
    </div>
  )
}

export default observer(Table)
