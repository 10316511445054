import { Instance, applySnapshot, flow } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import {
  IBulkUpdateClaimIssueStatusResponse,
  bulkUpdateClaimIssuesStatus,
} from '../../api/assets-api/contentId/bulkUpdateClaimIssuesStatus'
import { IGetClaimResponse, getClaim } from '../../api/assets-api/contentId/getClaim'
import {
  IRequestLicensesByEmailResponse,
  requestLicensesByEmail,
} from '../../api/assets-api/contentId/requestLicensesByEmail'
import { IRequestToTakeActionResponse, requestToTakeAction } from '../../api/assets-api/contentId/requestToTakeAction'
import { IUpdateClaimHistoryResponse, updateClaimHistory } from '../../api/assets-api/contentId/updateClaimHistory'
import { CLAIM_STATUSES, CLAIM_TAKE_ACTION_ROLE } from '../../constants'
import { IAssetUser } from '../assets/AssetUser.model'
import { ClaimBasic } from './ClaimBasic.model'

export const Claim = ClaimBasic.volatile(() => ({
  loading: false,
}))
  .views(self => ({
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IGetClaimResponse = yield getClaim({ claimId: self.claimId || '' })
        if (resp && resp?.data.data?.claim) {
          applySnapshot(self, { ...self, ...resp.data.data.claim })
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    updateClaimWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
    loadHistory: flow(function* () {
      try {
        self.loading = true

        const resp: IUpdateClaimHistoryResponse = yield updateClaimHistory(self.claimId || '')
        if (resp && resp?.data.data?.updateClaimHistory) {
          applySnapshot(self, {
            ...self,
            ...resp.data.data.updateClaimHistory,
          })

          yield self.load()
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    updateIssueStatus: flow(function* (newStatus: CLAIM_STATUSES) {
      if (!self.claimId) {
        return
      }

      if (newStatus === CLAIM_STATUSES.TAKEDOWN) {
        return
      }

      try {
        self.loading = true

        const resp: IBulkUpdateClaimIssueStatusResponse = yield bulkUpdateClaimIssuesStatus({
          newStatus,
          claimIds: [self.claimId],
        })
        if (
          resp &&
          resp.data.data?.bulkUpdateClaimIssuesStatus &&
          resp.data.data.bulkUpdateClaimIssuesStatus.length > 0
        ) {
          const claimResp = resp.data.data.bulkUpdateClaimIssuesStatus[0]

          if (claimResp.isUpdated) {
            if (claimResp.claim) {
              applySnapshot(self, { ...self, ...claimResp.claim })
            }
            toast.success(`Successfully updated claim:${claimResp.claimId} `)
          }

          if (!claimResp.isUpdated && claimResp.error) {
            toast.error(`Failed to update claim: ${claimResp.claimId} - ${claimResp.error}`)
          }
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    requestToTakeAction: flow(function* (props: {
      takeActionRole: CLAIM_TAKE_ACTION_ROLE
      days?: number
      requestedStatus?: string
      takeActionBy?: string
    }) {
      try {
        if (!self.claimId) {
          return
        }
        self.loading = true

        const resp: IRequestToTakeActionResponse = yield requestToTakeAction({
          takeActionRole: props.takeActionRole,
          claimIds: [self.channelId || ''],
          days: props.days,
          requestedStatus: props.requestedStatus,
          takeActionBy: props.takeActionBy,
        })

        if (resp && resp.data.data?.requestToTakeAction && resp.data.data.requestToTakeAction.length > 0) {
          const claimResp = resp.data.data.requestToTakeAction[0]

          if (claimResp.isUpdated) {
            if (claimResp.claim) {
              applySnapshot(self, { ...self, ...claimResp.claim })
            }
            toast.success(`Successfully updated claim:${claimResp.claimId} `)
          }

          if (!claimResp.isUpdated && claimResp.error) {
            toast.error(`Failed to update claim: ${claimResp.claimId} - ${claimResp.error}`)
          }
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),

    requestLicensesByEmail: flow(function* (email: string, userUuid: string) {
      if (!self.claimId) {
        return
      }

      try {
        self.loading = true

        const resp: IRequestLicensesByEmailResponse = yield requestLicensesByEmail({
          email,
          claimIds: [self.claimId],
          userUuid,
        })
        if (resp && resp.data.data?.requestLicensesByEmail && resp.data.data.requestLicensesByEmail.length > 0) {
          const claimResp = resp.data.data.requestLicensesByEmail[0]

          if (claimResp.isUpdated) {
            if (claimResp.claim) {
              applySnapshot(self, { ...self, ...claimResp.claim })
            }
            toast.success(`Request sent`)
          }

          if (!claimResp.isUpdated && claimResp.error) {
            toast.error(`Failed to send request`)
          }
        }
        self.loading = false
      } catch (err) {
        console.error(err)
      }

      self.loading = false
    }),
  }))

export type IClaim = Instance<typeof Claim>
