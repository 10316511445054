import { Instance, types } from 'mobx-state-tree'
import { CLAIM_TAKE_ACTION_ROLE } from '../../constants'
import { AssetUser } from '../assets/AssetUser.model'
import { ClaimHistory } from './ClaimHistory.model'
import { ClaimMatchInfo } from './ClaimMatchInfo.model'
import { ClaimPolicy } from './ClaimPolicy.model'
import { ClaimStudioInfo } from './ClaimStudioInfo.model'

export const ClaimBasic = types.model({
  uuid: types.string,
  users: types.array(AssetUser),
  siteUuid: types.maybeNull(types.string),
  cmsUuid: types.maybeNull(types.string),
  claimId: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  viewCount: types.maybeNull(types.number),
  assetId: types.maybeNull(types.string),
  assetUuid: types.maybeNull(types.string),
  assetTitle: types.maybeNull(types.string),
  videoId: types.maybeNull(types.string),
  videoTitle: types.maybeNull(types.string),
  videoUploadDate: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  channelName: types.maybeNull(types.string),
  claimDate: types.maybeNull(types.string),
  expiresAt: types.maybeNull(types.string),
  isVideoShortsEligible: types.maybeNull(types.boolean),
  isPrivateVideo: types.maybeNull(types.boolean),
  contentType: types.maybeNull(types.string),
  tpartyClaim: types.maybeNull(types.string),
  releasedBy: types.maybeNull(types.string),
  releasedAt: types.maybeNull(types.string),
  isEarningSynced: types.maybeNull(types.boolean),
  releasedYoutubeAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  ugcType: types.maybeNull(types.string),
  timeStatusLastModified: types.maybeNull(types.string),
  blockOutsideOwnership: types.maybeNull(types.boolean),
  isPartnerUploaded: types.maybeNull(types.boolean),
  statusSyncedAt: types.maybeNull(types.string),
  // TODO check if this is on the BE and requested in the fragment and displayed
  reinitiatedBy: types.maybeNull(types.string),
  reinitiatedAt: types.maybeNull(types.string),
  history: types.maybeNull(ClaimHistory),
  matchInfo: types.maybeNull(ClaimMatchInfo),
  studioInfo: types.maybeNull(ClaimStudioInfo),
  policy: types.maybeNull(ClaimPolicy),
  appliedPolicy: types.maybeNull(ClaimPolicy),
  origin: types.maybeNull(types.model({ source: types.maybeNull(types.string) })),
  takeActionRole: types.maybeNull(types.enumeration(Object.values(CLAIM_TAKE_ACTION_ROLE))),
  takeActionBy: types.maybeNull(types.string),
  takeActionEmailSentAt: types.maybeNull(types.string),
  takeActionExpiresAt: types.maybeNull(types.string),
  takeActionRequestedStatus: types.maybeNull(types.string),
  takeActionLicenseRequestedAt: types.maybeNull(types.string),
  takeActionReason: types.maybeNull(types.string),
  emailTemplateUuid: types.maybeNull(types.string),
  autoReleasedAt: types.maybeNull(types.string),
  autoReleasedType: types.maybeNull(types.string),
})
export type IClaimBasic = Instance<typeof ClaimBasic>
