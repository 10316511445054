export const ASSET_PREFIX = 'ast'

export const UPLOAD_PER_PAGE = 30
export const DRAFT_SAVE_DELAY = 1000
export const SEARCH_CALL_DELAY = 750

export const AUDIO_FILE_EXTENSIONS = ['mp3', 'wav', 'm4a', 'flac']
export const VIDEO_FILE_EXTENSIONS = ['mp4', 'ogg']

export const AUDIO_MIME_TYPES = 'audio/*'
export const AUDIO_X_WAV_MIME_TYPE = 'audio/x-wav'
export const AUDIO_WAV_MIME_TYPE = 'audio/wav'
export const AUDIO_X_FLAC_MIME_TYPE = 'audio/x-flac'
export const AUDIO_X_FLAC_TYPE = 'audio/flac'
export const AUDIO_MP3_MIME_TYPE = 'audio/mpeg'
export const AUDIO_AIFF_MIME_TYPE = 'audio/aiff'

export const TRACKS_MIME_TYPES = [AUDIO_X_WAV_MIME_TYPE, AUDIO_WAV_MIME_TYPE, AUDIO_X_FLAC_MIME_TYPE, AUDIO_X_FLAC_TYPE]

export const VIDEO_MIME_TYPES = 'video/*'

export const IMG_MIME_TYPES = 'image/*'
export const IMG_JPEG_MIME_TYPE = 'image/jpeg'

export const TAX_FORM_MIME_TYPES = 'image/*,application/pdf'

export const CSV_MIME_TYPE = 'text/csv'

export const AUDIO_MINIMUM_DURATION = 20
export const VIDEO_MINIMUM_DURATION = 20
export const DEFAULT_MAX_FILES_DROPZONE = 50
export const MAX_FILES_CSV_AUDIO_UPLOAD = 510

export const MAX_FILES_PER_TYPE: { [key: string]: number } = {
  Album: 100,
  EP: 7,
  Single: 2,
}

export const AUT_CSV_REQUIRED_FIELDS = {
  TITLE: 'title',
  ARTIST: 'artist',
  ALBUM: 'album',
  ISRC: 'isrc',
  UPC: 'upc',
  LABEL: 'label',
  CUSTOM_ID: 'customId',
  ISWC: 'iswc',
  IS_COVER: 'isCover',
  FILENAME: 'filename',
}
