export const transferFragment = `
  type
  amount
  status
  isRetryable
  createdAt
  updatedAt
  paymentMethodUuid
`

export const earningFragment = `
  uuid
  paymentUuid
  paymentDate
  userUuid
  siteUuid
  type
  active
  netPayable
  createdBy
  createdAt
  updatedAt
  runUuid
  comment
  assetEarning
  assetRedEarning
  assetAdjEarning
  assetRedAdjEarning
  publishingAssetEarning
  publishingAssetRedEarning
  publishingAdjAssetEarning
  publishingAdjAssetRedEarning
  contentId
  shortsAdEarning
  shortsAdEarningBeforeTax
  shortsRedEarning
  shortsRedEarningBeforeTax
  channelEarning
  channelEarningBeforeTax
  channelRedEarning
  channelRedEarningBeforeTax
  channelAdjEarning
  channelAdjEarningBeforeTax
  channelRedAdjEarning
  channelRedAdjEarningBeforeTax
  transactionEarning
  transactionEarningBeforeTax
  transactionCommission
  nonTransactionCommission
  isBonusPeriod
  revenuePercentage
  contentIdRevenuePercentage
  bonusRevenuePercentage
  transactionRevenuePercentage
  revenueShares
  affiliations
  affiliateEarning
  affiliateDeduction
  affiliateDeductionDetailed
  affiliatePercentage
  revenueSplits
  splitDeductions
  splitAdditions
  splitAdditionsTotal
  splitDeductions
  distributionNetEarning
  distributionGrossEarning
  distributionCommission
  monthlyAdjustment
  youtubeMonthlyAdjustment
  youtubeAdjustment
  youtubeAdjustmentBeforeTax
  grossEarning
  grossCommission
  netEarning
  netCommission
  taxRates
  taxWithheld
  taxRate
  taxWithheldCalculated
  taxWithheldBasedOnLastMonthRate  
  claimSplitGrossEarning
  claimSplitNetEarning
  claimSplitGrossDeduction
  claimSplitNetDeduction
  whtAdjustment
  adjustmentsDetailed {
    amount
    comment
  }
`
export const earningWithSponsorshipFragment = `
  ${earningFragment}
  grossSponsorship
  netSponsorship
  commissionSponsorship
`

export const earningHighlightReportFragment = `
  netEarning
  grossEarning
  uuid
  userUuid
  siteUuid
  createdAt
  updatedAt
  netCommission
  affiliateEarning
  type
  paymentDate
  active
`

export const userAffiliationsBreakdownFragment = `
  userUuid
  grossEarning
  networkShare
`

export const eligibleUsersFragment = `
  uuid
  children
  isEarly
  doNotPay
`

export const balanceFragment = `
  totalEarnings
  totalPaid
  totalUnpaid      
  userUuid
`

export const currenciesFragment = `
  uuid 
  currencyCode 
  displayName 
  paymentMethodUuid
  excludedPaymentMethodUuids
`

export const createEarningFragment = `
  uuid
  type
  siteUuid
  userUuid
  paymentDate
  assetEarning
  assetRedEarning
  channelEarning
  channelRedEarning
  publishingAssetEarning
  publishingAssetRedEarning
  youtubeAdjustment
  grossEarning
  revenuePercentage
  grossCommission
  affiliatePercentage
  affiliateEarning
  affiliateDeduction
  affiliations
  netCommission
  monthlyAdjustment
  netEarning
  active
  createdAt
  updatedAt
  payment {
    uuid
    status
    userUuid
    paymentDate
    amount
    createdAt
    updatedAt
  }
`

export const getEarningsFragment = `
  ${earningFragment}
  payment {
    uuid
    status
    isEarlyPercentage
    isEarly
    netCommission
    isPayable
    userUuid
    paymentDate
    amount
    createdAt
    updatedAt
    transfers {
      uuid
      type
      amount
      status
      providerStatus
      isRetryable
      feeAmount
      createdAt
      updatedAt
      paymentMethodUuid
    }
  }
`
